//hosting url for api of cost-it-right

// export const BASE_URL = 'http://10.10.10.161:4000';
export const superAdminId = 0;
export const FILE_SIZE = 5242880;
export const SESSIONEXPIRED = "session-expired";

//------------------Local ports----------------
// export const BASE_URL = 'http://10.10.0.194:4003';
// export const PROFILE_MEDIA_URL = 'http://10.10.0.194:4003'

// export const BASE_URL = 'http://localhost:4000';
// export const PROFILE_MEDIA_URL = 'http://localhost:4000'

// export const BASE_URL = 'http://localhost:4001';
// export const PROFILE_MEDIA_URL = 'http://localhost:4001'

// export const BASE_URL = 'http://10.10.10.161:4000';
// export const PROFILE_MEDIA_URL = 'http://10.10.10.161:4000'

//------------------Live  ports----------------
// export const BASE_URL = 'http://asm.siplsolutions.com:3000'
// export const PROFILE_MEDIA_URL = 'http://asm.siplsolutions.com:3000'

// ------------------Client  ports----------------
// export const BASE_URL = "http://localhost:4200";
// export const PROFILE_MEDIA_URL = "http://localhost:4200";

// export const BASE_URL = 'http://3.19.252.218:3000';
// export const PROFILE_MEDIA_URL = 'http://3.19.252.218:3000';

// export const BASE_URL = 'https://pijen.com/webservice/';
// export const PROFILE_MEDIA_URL = 'https://pijen.com/webservice/';

export const BASE_URL = 'https://stage.pijen.com/webservice/';
export const PROFILE_MEDIA_URL = 'https://stage.pijen.com/webservice/';

// export const BASE_URL = 'https://pijen.com/webservice/';
// export const PROFILE_MEDIA_URL = 'https://pijen.com/webservice/';

export const API = {
  //configure api'sz
  getAdminDetail: `${BASE_URL}/api/adminById`,
  updateAdminPassword: `${BASE_URL}/api/changePassword`,
  updateSuperAdminDetails: `${BASE_URL}/api/admin`,
  updateAdminDetails: `${BASE_URL}/api/subAdmin`,
  deleteAdminDetails: `${BASE_URL}/api/admin`,
  getRole: `${BASE_URL}/api/roles`,
  registerAdmin: `${BASE_URL}/api/register`,
  getAllAdmin: `${BASE_URL}/api/admin`,
  getAllNewspaperList: `${BASE_URL}/api/newspaperList`,
  getAllNewspaperById: `${BASE_URL}/api/newspaperById`,
  getemailSettings: `${BASE_URL}/api/getemailSettings`,
  getWhatsappSettings: `${BASE_URL}/api/getWhatsappSettings`,
  getPayoutSettings: `${BASE_URL}/api/getPayoutSettings`,
  getCustomURLSettings: `${BASE_URL}/api/getCustomURLSettings`,
  getSendEmailList: `${BASE_URL}/api/getSendEmailList`,
  activateAdmin: `${BASE_URL}/api/subAdmin`,
  getLocation: `${BASE_URL}/api/location`,
  deleteCompanyDetails: `${BASE_URL}/api/newspaper`,
  updateCompanyDetails: `${BASE_URL}/api/newspaper`,
  changeCompanyStatus: `${BASE_URL}/api/changeStatus`,
  addNewsPaperCompany: `${BASE_URL}/api/newspaper`,
  addMultipleNewCompany: `${BASE_URL}/api/newspaperBulk`,
  getBankDetailsById: `${BASE_URL}/api/getBankDetailsByAltId`,
  sendMailToUpdatePaymentDetails: `${BASE_URL}/api/sendMailToUpdatePaymentDetails`,
  //changes for open api
  //addNewsPaperCompany: `${BASE_URL}/open/newspaper`,
  uniqueEmail: `${BASE_URL}/api/uniqueEmail`,
  uniqueEmailForEdit: `${BASE_URL}/api/duplicateEmail`,
  getCompanyById: `${BASE_URL}/api/newspaperById`,
  getCustomerRequestList: `${BASE_URL}/api/newspaperList`,
  searchInNewspaperList: `${BASE_URL}/api/searchNewspaper`,
  searchInUserList: `${BASE_URL}/api/searchUser`,
  rejectRequest: `${BASE_URL}/api/changeStatus`,
  getRecordsFromAdvanceSearch: `${BASE_URL}/api/advanceNewspaper`,
  messageCompany: `${BASE_URL}/api/sentMessage`,
  addWhatsappNumber: `${BASE_URL}/api/addWhatsappNumber`,
  //----------- MileStone-2 ------------------------
  createCustomer: `${BASE_URL}/api/customer`,
  getCustomerList: `${BASE_URL}/api/users`,
  roles: `${BASE_URL}/api/roles`,
  rolesWithCount: `${BASE_URL}/api/roles_with_count`,
  genericAPI: `${BASE_URL}/api/generic`,
  genericUsers: `${BASE_URL}/api/generic/users`,
  getCustomerById: `${BASE_URL}/api/generic/customer_view`,
  searchInCustomerList: `${BASE_URL}/api/searchUser1`,
  genericAdTypeAPI: `${BASE_URL}/api/generic/advertisement`,
  genericColorAPI: `${BASE_URL}/api/generic/color`,
  getAdType: `${BASE_URL}/api/ad_type`,
  getClassifiedTemplate: `${BASE_URL}/api/classified_template`,
  updateEmailInfo: `${BASE_URL}/api/updateEmailInfo`,
  updateWhatsappInfo: `${BASE_URL}/api/updateWhatsappInfo`,
  updatePayoutInfo: `${BASE_URL}/api/updatePayoutInfo`,
  updateCustomURLInfo: `${BASE_URL}/api/updateCustomURLInfo`,
  updateSendEmailList: `${BASE_URL}/api/updateSendEmailList`,

  genericRoleAPI: `${BASE_URL}/api/generic/roles`,
  genericNewsPaper: `${BASE_URL}/api/generic/newspapers`,
  generalAPI: `${BASE_URL}/api`,

  //----------- MileStone-3 ------------------------
  getAllOrderList: `${BASE_URL}/admin/orders`,
  getAllRecivedOrderList: `${BASE_URL}/api/pending_orders`,
  ordersApi: `${BASE_URL}/api/orders`,
  getAdvanceSearchRecordOrderList: `${BASE_URL}/admin/ad_orders`,

  getAllCreditList: `${BASE_URL}/api/credits`,
  getAdvanceSearchRecordCreditList: `${BASE_URL}/api/ad_credits`,

  emailTemplate: `${BASE_URL}/admin/email_template`,
  actionTemplate: `${BASE_URL}/admin/action_template`,
  actionTemplateById: `${BASE_URL}/admin/action_template_by_id`,
  testEmail: `${BASE_URL}/admin/test_email`,

  /** milestone 3 */
  sendLandingEmail: `${BASE_URL}/admin/send_landing_email`,
  advertisePlan: `${BASE_URL}/admin/advertise_plan`,
  planActivity: `${BASE_URL}/admin/planActivationStatusUpdate`,
  socialMediaPlan: `${BASE_URL}/admin/social_media_plan`,
  outOfHome: `${BASE_URL}/admin/out_of_home`,
  adminSecondaryEmail: `${BASE_URL}/admin/secondary_email`,
  adminSecondaryEmail1: `${BASE_URL}/admin/secondary_email1`,
  getNewspaperById: `${BASE_URL}/admin/newspaper_by_id`,
  getCompanyCategory: `${BASE_URL}/admin/get_company_category`,
  advertisePlanAdvancedSearch: `${BASE_URL}/admin/advertise_plan_ad_search`,
  getNotification: `${BASE_URL}/admin/notification`,
  getWhatsappNumber: `${BASE_URL}/admin/getWhatsappNumber`,
  /** Milestone-5 */
  //getPayoutHistory: `${BASE_URL}/api/payment`,
  getPayoutHistory: `${BASE_URL}/api/getAllNewpaperPendingTransaction`,
  getPaidPayoutHistory: `${BASE_URL}/api/getNewspaperTransaction`,
  getTransactionDetail: `${BASE_URL}/api/getNewpaperAllTransaction`,
  getSalesReport: `${BASE_URL}/api/sales`,
  getAdTypeReport: `${BASE_URL}/api/ad_type_report`,
  getAdTypeDropdown: `${BASE_URL}/api/ad_type_list`,
  getCompanySummaryReport: `${BASE_URL}/api/company_summary`,
  getCustomerSummaryReport: `${BASE_URL}/api/customer_summary`,
  getPayoutSummary: `${BASE_URL}/api/getNewpaperAllTransactionreport`,
  getDetailsofPublisher: `${BASE_URL}/api/getDetailsofPublisher`,
  getCustomerListing: `${BASE_URL}/api/customerPayoutTotalDetails`,
  getCustomerWithrespectoAgent: `${BASE_URL}/api/getDetailsOfClientWRTAgent`,
  getDetailsWithrespectoPublisher: `${BASE_URL}/api/getDetailsofAgentWRTpublisher`,

  getcustomertotalValues: `${BASE_URL}/api/customertotalValues`,
  getCustomerTransactionDetails: `${BASE_URL}/api/transactionInformation`,
  getPijentotalValues: `${BASE_URL}/api/pijentotalValues`,
  getPijenDetails: `${BASE_URL}/api/pijenSalesTotalDetails`,
  getPijenTransactionDetails: `${BASE_URL}/api/pijenTransactionInformation`,

  getCustomergraphicalData: `${BASE_URL}/api/graphicalData`,
  getAgentGraphDetails: `${BASE_URL}/api/agentGraphicalData`,
  getPublisherGraphDetails: `${BASE_URL}/api/publisherGraphicalData`,
  getPublishertotalValues: `${BASE_URL}/api/publishertotalValues`,
  getpublisherPayoutTotalDetails: `${BASE_URL}/api/publisherPayoutTotalDetails`,

  getDetailsofAgent: `${BASE_URL}/api/getDetailsofAgent`,
  getSalesReportDetail: `${BASE_URL}/api/sa_sales`,
  getSampleCsv: `${BASE_URL}/api/sample_csv`,
  getOrderReportDetail: `${BASE_URL}/api/sa_orders`,
  getAdtypeReportDetail: `${BASE_URL}/api/sa_ad_report`,
  getAdminDashboardRecord: `${BASE_URL}/api/admin_dashboard`,
  getNpcDashboardRecord: `${BASE_URL}/api/npc_dashboard`,
  getCustomerAccessRequestList: `${BASE_URL}/api/customer_access_list`,
  getAgentDiscountList: `${BASE_URL}/api/get_discount_for_newspaper`,
  getAgentEditPublisherList: `${BASE_URL}/api/get_agent_publisher_iseditable`,
  customerAccessRequest: `${BASE_URL}/api/npc_customer_request`,
  enableandDisablediscount: `${BASE_URL}/api/enable_disable_discount`,
  enableDisablePublisherEditable: `${BASE_URL}/api/enable_disable_publisher_iseditable`,
  getadminEditedPriceList: `${BASE_URL}/api/get_api_price_list`,
  customerAcessPermission: `${BASE_URL}/api/admin_customer_request`,
  checkCustomerExits: `${BASE_URL}/api/check_customer`,
  addCustomerDiscount: `${BASE_URL}/api/customer_discount`,
  bussinessHours: `${BASE_URL}/api/business_hour`,
  customerDetail: `${BASE_URL}/api/customer_details`,
  advertisePlanUpdate: `${BASE_URL}/admin/advertise_plan_update`,
  socialPlanUpdate: `${BASE_URL}/admin/social_media_plan_update`,
  getVersions: `${BASE_URL}/admin/getVersions`,
  getVersionsForSocial: `${BASE_URL}/admin/getScoialVersions`,
  getPlanByversionId: `${BASE_URL}/admin/getPlanByversionId`,
  getPlanByversionIdForSocial: `${BASE_URL}/admin/getSocialPlanByversionId`,
  getActiveSubscriptions: `${BASE_URL}/admin/getActiveSubscriptions`,
  getActiveSubscriptionsDetails: `${BASE_URL}/admin/getActiveSubscriptionsDetails`,
  getActiveSubscriptionsInvoices: `${BASE_URL}/admin/getAllSubscriptionInvoice`,
  subscriptionInvoiceDownload: `${BASE_URL}/admin/subscriptionInvoiceDownload`,
  subscriptionStatusOfAgent: `${BASE_URL}/api/getUserSubscriptionStatus`,
  getUsersActivityLog: `${BASE_URL}/admin/users_activity_log`,
  agentLoginFromAdmin: `${BASE_URL}/admin/agent_login_from_admin`,
  getAllEditionList: `${BASE_URL}/api/getEditionByDate`,
  getLinkExpired: `${BASE_URL}/api/open_token_expires`,
  seasonalIssues: `${BASE_URL}/admin/seasonalIssues`,
  seasonalIssueOverrideEvent:`${BASE_URL}/api/seasonalIssueOverrideEvent`,
  getSeasonalIssuesByPublisher:`${BASE_URL}/api/getSeasonalIssuesByPublisher`,
  deleteSeasonalIssueOverrideEvent:`${BASE_URL}/api/deleteSeasonalIssueOverrideEvent`,
  updateSeasonalIssueOverrideEvent:`${BASE_URL}/api/updateSeasonalIssueOverrideEvent`,
  getAllActiveSeasonalIssueForUser: `${BASE_URL}/api/getAllActiveSeasonalIssueForAllUser`,
  getAllPublisherForParticularSeasonalIssue: `${BASE_URL}/api/getAllPublisherForParticularSeasonalIssue`
};

//Api constants
export const API_REQUEST = "API_REQUEST";
export const API_FAILURE = "API_FAILURE";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";

//email-campaign app info
export const appVersion = "v1_EmailCapmaign";
export const USERDATA_KEY = "userf649fc9a5f44";
export const Auth_KEY = "authf649fc9a5f55";

//Super admin email and role id
export const SUPERADMIN_EMAIL = "moshe@yourcompleteweb.com";
export const SUPERADMIN_ROLE_ID = 0;
